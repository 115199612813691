/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * This will load the Vegareader or details forms
 * based on the user entered details
 *
 * @file App.js
 * @author Srinivasan Bakthavatchalam
 * @since 2.0.0
 */

import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import pearsonTheme from '@greenville/mui-theme';

import { BOOK_DATA_ARRAY } from '../src/common/constants';

import DetailsForm from './demo/DetailsForm';
import VegaReaderContainer from './demo/VegaReaderContainer';
import { BrowserRouter, Routes, Route } from "react-router-dom";


import appConfig from '../src/config/appConfig';

import DetailsFormUtils from '../src/utils/DetailsFormUtils';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookId: '',
      contentType: ''
    };
  }

  setDetails = (values) => {
    try {
      const tempValues = values;
      this.setState(tempValues);
    } catch (e) {
      this.setState({
        bookId: '',
        contentType: ''
      });
    }
    if (values.token) {
      window.piSession = {};
      window.piSession.currentToken = () => values.token;
    }
  }
  handleGetBooks = (bookDataArray) => {
    const details = new DetailsFormUtils();
    const { getBooksFromLocalStorage } = details;
    const stateHandler = getBooksFromLocalStorage(bookDataArray);

    return stateHandler;
  };
  handleState = () => {
    const details = new DetailsFormUtils();
    const { getLatestStateData } = details;
    const stateHandler = getLatestStateData();

    return stateHandler;
  };
  render() {
    const selectedTheme = createMuiTheme(pearsonTheme);

    return <MuiThemeProvider theme={selectedTheme}>

  <BrowserRouter>
      <Routes>
        <Route path="/" element={<DetailsForm setDetails={this.setDetails} />}>
        </Route>
        <Route path="/book" element={<VegaReaderContainer {...this.handleGetBooks(BOOK_DATA_ARRAY) ? this.handleState() : appConfig.updated } />}>
        </Route>
      </Routes>
    </BrowserRouter>
  </MuiThemeProvider>
    ;
  }
}
export default App;
